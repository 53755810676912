import React from 'react';

const EventsPage: React.FC = () => {
  return (
    <div className="events-page">
      <section className="hero-sub-page">
        <img className="bg" src="/assets/pages/events-page/hero.png" />
        <div className="container">
          <h1>
            Do you need our help to organise <b>your event?</b>
          </h1>
          <h2>Exceptional experience with your logotype.</h2>
        </div>
      </section>
      <section className="section-1 reverse">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2 className="content">
            Our employees have several years of experience in organizing corporate events, birthdays and festivals: not only in Poland, but also abroad.
            </h2>
            <p>
            We invite you to familiarize yourself with our offer and enrich your event with the possibility of moving participants to any place in the world!
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="https://images.unsplash.com/photo-1459550428001-4ed6ca421293?ixlib=rb-1.2.1&auto=format&fit=crop&w=2446&q=80"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="opportunities">
        <div className="container">
          <div className="item">
            <img src="/assets/pages/events-page/party-icon.svg" alt="party icon" />
            <h3>Emotions</h3>
            <p>Virtual reality is developing dynamically and provides a lot of emotions.</p>
          </div>
          <div className="item">
            <img src="/assets/pages/events-page/people-icon.svg" alt="party icon" />
            <h3>Integration</h3>
            <p>Organizing such an event is a great way to integrate employees.</p>
          </div>
          <div className="item">
            <img src="/assets/pages/events-page/birthday-icon.svg" alt="party icon" />
            <h3>Celebration</h3>
            <p>VR is a creative way to celebrate important events (birthdays, weddings) that everyone will remember.</p>
          </div>
        </div>
      </section>
      <section className="section-1 ">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2 className="content">9D Cinema</h2>
            <p>
            Our cinema with a rich repertoire of games and applications will allow participants to experience all kinds of emotions! Everyone, regardless of age, will find something for themselves!
            </p>
            <p>
            Our simulator will allow you to ride the biggest roller coasters in the world, immerse yourself in beautiful, fairy-tale worlds, but also get goosebumps during one of our horror movies!
            </p>
            <p>
              <strong>
                <b>Choose your dream show, sit comfortably and try not to fall out of your chair!</b>
              </strong>
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img className="image" src="/assets/pages/events-page/kv-1.png" alt="sample" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-1 reverse">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2 className="content">360 degrees simulator</h2>
            <p>
            We encourage you to rent a rotary machine. It is one of the few simulators in Europe that rotates 360 degrees in the movies and allows you to hang on the straps!
            </p>
            <p>
              <b>
                <strong>
                Would you like to feel the adrenaline while exploring space? Or maybe become a star wars starship pilot and shoot other starfighters?
                </strong>
              </b>{' '}
              No problem, just fasten your seatbelts, because this machine will give you much more than you can imagine!
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="https://images.unsplash.com/photo-1496317556649-f930d733eea3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-1 ">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2 className="content">VR Games</h2>
            <p>
            If you want to make the participants of your event even more involved in it, we recommend renting VR games!
            </p>
            <p>
              <b>
                <strong>
                These are interactive experiences where we can e.g. explore underwater worlds, shoot zombies, ride the fastest cars in the world, or get your guests moving with a party music game!{' '}
                </strong>
              </b>
            </p>
            <p>
              This kind of fun, along with the possibility of competition, will make participants never forget about your event!
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="https://images.unsplash.com/photo-1588336443962-49d88df004a1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1602&q=80"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EventsPage;
