import { getLanguage } from './functions/getLanguage';
import HomePage from './pages/HomePage';
import GalleryPage from './pages/GalleryPage';
import SoftwarePage from './pages/SoftwarePage';
import OfferPage from './pages/OfferPage';
import EventsPage from './pages/EventsPage';
import ProductPage from './pages/ProductPage';

function getSlug(slugPL: string, slugEN: string, lang?: string): string {
  if (!lang) {
    switch (getLanguage()) {
      case 'pl':
        return slugPL;

      case 'en':
        return slugEN;

      default:
        return '/';
    }
  } else {
    switch (lang) {
      case 'pl':
        return slugPL;

      case 'en':
        return slugEN;

      default:
        return '';
    }
  }
}

function getName(namePL: string, nameEN: string, lang?: string): string {
  if (!lang) {
    switch (getLanguage()) {
      case 'pl':
        return namePL;

      case 'en':
        return nameEN;

      default:
        return '';
    }
  } else {
    switch (lang) {
      case 'pl':
        return namePL;

      case 'en':
        return nameEN;

      default:
        return '';
    }
  }
}

export const Routes = {
  names: {
    HOME_PAGE: 'Home',
    OFFER_PAGE: 'Offer',
    SOFTWARE_PAGE: 'Software',
    GALLERY_PAGE: 'Gallery',
    EVENTS_PAGES: 'Events'
  },

  objects: [
    {
      getSlug: (lang?: string) => getSlug('/', '/en', lang),
      getName: (lang?: string) => getName('Home', 'Home', lang),
      component: HomePage,
      header: false,
      footer: false
    },

    {
      getSlug: (lang?: string) => getSlug('/oferta', '/en/offer', lang),
      getName: (lang?: string) => getName('Offer', 'Offer', lang),
      component: OfferPage,
      header: true,
      footer: false
    },
    {
      getSlug: (lang?: string) => getSlug('/galeria', '/en/gallery', lang),
      getName: (lang?: string) => getName('Gallery', 'Gallery', lang),
      component: GalleryPage,
      header: true,
      footer: false
    },
    {
      getSlug: (lang?: string) => getSlug('/eventy', '/en/events', lang),
      getName: (lang?: string) => getName('Events', 'Events', lang),
      component: EventsPage,
      header: true,
      footer: false
    },
    {
      getSlug: (lang?: string) => getSlug('/oprogramowanie', '/en/software', lang),
      getName: (lang?: string) => getName('Software', 'Software', lang),
      component: SoftwarePage,
      header: true,
      footer: false
    },
    {
      getSlug: (lang?: string) => getSlug('/produkt/:slug', '/en/product/:slug', lang),
      getName: (lang?: string) => getName('Prducts', 'Product', lang),
      component: ProductPage,
      header: false,
      footer: false
    }
  ]
};

export function getHeaderRoutes() {
  return Routes.objects.filter((route) => {
    return route.header;
  });
}

export function getRoutes() {
  return Routes.objects;
}

export function getRoutesNames() {
  return Routes.names;
}

export function getPathnameSlug(lang: string): string {
  const path = document.location.pathname;

  const currentPath = getRoutes().filter((route) => {
    return route.getSlug() === path;
  });

  if (currentPath.length > 0) {
    return currentPath[0].getSlug(lang);
  } else {
    return getDefaultPath();
  }
}

export function getDefaultPath(): string {
  if (getLanguage() === 'pl') {
    return '/';
  } else {
    return `/${getLanguage()}`;
  }
}

export function getRouteLink(name: string): any {
  const route = getRoutes().filter((route) => {
    return route.getName('en') === name;
  });

  if (route.length > 0) {
    return route[0].getSlug();
  } else return getDefaultPath();
}
