import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import Root from './app/Root';
import 'normalize.css';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <Router>
    <Root />
  </Router>,
  document.getElementById('root')
);
