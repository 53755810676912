import React, { useState, useEffect } from 'react';
import 'react-bnb-gallery/dist/style.css';
import { isMobile } from 'react-device-detect';

import ReactBnbGallery from 'react-bnb-gallery';

const GalleryPage: React.FC = () => {
  const [images, setImages] = useState<any>([]);
  const [isLightboxOpened, setIsLightboxOpened] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  useEffect(() => {
    fetch('/data/Gallery.json')
      .then((response) => response.json())
      .then((data) => {
        setImages(data.images);
      });
  }, []);

  useEffect(() => {
    if (isLightboxOpened) {
      document.body.style.overflow = 'hidden';
      if (!isMobile) {
        document.body.style.paddingRight = '10px';
      }
    } else {
      setTimeout(() => {
        document.body.style.overflow = 'unset';
        document.body.style.paddingRight = '0px';
      }, 300);
    }
  }, [isLightboxOpened]);

  const lightboxArray = () => {
    const tempArray: any = [];

    images?.map((item) => {
      tempArray.push(`/assets/pages/gallery-page/${item}`);
    });

    return tempArray;
  };

  return (
    <>
      {isLightboxOpened ? (
        <ReactBnbGallery
          show={isLightboxOpened}
          activePhotoIndex={photoIndex}
          photos={lightboxArray()}
          onClose={() => setIsLightboxOpened(false)}
          showThumbnails={false}
          opacity={0.85}
        />
      ) : null}

      <div className="gallery-page">
        <section className="hero-sub-page">
          <img className="bg" src="/assets/pages/software-page/hero.png" />
          <div className="container">
            <h1>
              See <b>our machines</b>
            </h1>
            <h2>Full transparency and reliability</h2>
          </div>
        </section>
        <section>
          <div className="container no-padding">
            <div className="gallery-container">
              {images &&
                images?.map((item, i) => {
                  return (
                    <div
                      className="item"
                      onClick={() => {
                        setIsLightboxOpened(true);
                        setPhotoIndex(i);
                      }}
                    >
                      <img className="maximize" src="/assets/pages/gallery-page/maximize.svg" />
                      <img key={item} alt={item} src={`/assets/pages/gallery-page/${item}`} />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GalleryPage;
