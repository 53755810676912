import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import validatePhonePrefix from '../validators/validatePhonePrefix';

const Footer: React.FC<any> = ({ contactSectionRef }) => {
  const { handleSubmit, register, errors } = useForm();
  const [requestStatus, setRequestStatus] = useState('idle');

  //idle //pending //sent

  const onSubmit = (values) => {
    console.log(values);
	
    setRequestStatus('pending');
	fetch("sender.php", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify(values),
		}).then((response) => response.json())
.then((responseData) => {
	  console.log(responseData);
      if(responseData.status === "ok"){
           alert("Thanks for contacting us, we'll get back to you soon!");
      } else {
		    alert("An error occurred! Please send your email to contact@intensivevr.com");
		    console.log(responseData);
	  }
	  setRequestStatus('sent');
})

  };

  return (
    <div className="footer" ref={contactSectionRef}>
      <div className="header">Let's stay in touch!</div>
      <div className="container">
        <div className="left-container">
          <img className="logo" alt="logo" src="/assets/logo/logo_white.svg" />
          <div className="contact-informations">
            <div className="company-data">
              <span className="separator"></span>
              <p>
                <b>Company:</b>
                <br />
                RSI RADOSŁAW SZADKOWSKI
                <br />
                Tawęcino 67a
                <br />
                84-351 Nowa Wieś Lęborska
                <br />
                NIP: 841-163-73-35
              </p>
            </div>
            <div className="contact-data">
              <span className="separator"></span>
              <p>
                <b>Contact:</b>
                <br />
                contact@intensivevr.com
                <br />
                +48 668-278-235
                <br />
                +48 512-565-262
                <br />
                <a href="https://facebook.com/intensivevr">facebook</a>
                <br />
                <a href="https://instagram.com/intensivevr">instagram</a>
              </p>
            </div>
          </div>
          <span className="rights">© 2021 INTENSIVE VR | All rights reserved</span>
        </div>

        <div className="right-container">
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="top">
              <div className={`input ${errors.sender_name ? 'error' : ''}`}>
                <input
                  ref={register({
                    required: true,
                    pattern: { value: /^[\D]{2,}/, message: 'Field contains invalid value' }
                  })}
                  type="text"
                  name="sender_name"
                  placeholder="Name"
                />
                {errors.sender_name ? (
                  <span className="error">
                    {errors.sender_name.message != '' ? errors.sender_name.message : '*Field required'}
                  </span>
                ) : null}
              </div>
              <div className={`input ${errors.phone ? 'error' : ''}`}>
                <input
                  ref={register({
					minLength: 2,
                    maxLength: 18,
                    pattern: { value: /[+]?\d+/, message: 'Invalid phone number' },
                    validate: (value) => {
                      if (validatePhonePrefix(value, false)) {
                        return true;
                      } else {
                        return 'Invalid phone number';
                      }
                    }
                  })}
                  type="text"
                  name="phone"
                  placeholder="Phone (optional)"
                />
                {errors.phone ? (
                  <span className="error">{errors.phone.message != '' ? errors.phone.message : '*Field required'}</span>
                ) : null}
              </div>
            </div>
            <div className={`input ${errors.sender_email ? 'error' : ''}`}>
              <input
                ref={register({
                  required: true,
                  pattern: {
                    value: /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/,
                    message: 'Invalid e-mail'
                  }
                })}
                name="sender_email"
                placeholder="Email address"
              />
              {errors.sender_email ? (
                <span className="error">
                  {errors.sender_email.message != '' ? errors.sender_email.message : '*Field required'}
                </span>
              ) : null}
            </div>
            <div className={`input ${errors.content ? 'error' : ''}`}>
              <textarea
                ref={register({
                  required: true,
                  maxLength: 5000
                })}
                id="content"
                name="content"
                placeholder="Message"
              />
              {errors.content ? (
                <span className="error">
                  {errors.content.message != '' ? errors.content.message : '*Field required'}
                </span>
              ) : null}
            </div>
            <button
              className={`btn btn-blue ${
                requestStatus == 'idle' ? '' : requestStatus == 'pending' ? 'pending' : 'sent'
              }`}
              name="send_mail"
              type="submit"
            >
              {requestStatus == 'idle'
                ? 'Send'
                : requestStatus == 'pending'
                ? 'Please wait...'
                : 'Thanks for contacting us.'}
            </button>
          </form>
        </div>
      </div>
      <div className="bottom-bar" />
    </div>
  );
};

export default Footer;
