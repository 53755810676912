import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getRoutes, getDefaultPath } from './Routes';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import scrollToComponent from 'react-scroll-to-component';

const Root: React.FC = () => {
  const location = useLocation<Location>();

  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const [isNavVisible, setIsNavVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsNavOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  useScrollPosition(({ prevPos, currPos }) => {
    console.log(currPos);

    if (currPos.y < -50) {
      setIsNavVisible(true);
    } else {
      setIsNavVisible(false);
    }
  });

  const scrollToFooter = () => {
    setIsNavOpen(false);
    scrollToComponent(contactSectionRef.current, {
      offset: 0,
      align: 'top',
      duration: 500
    });
  };

  const contactSectionRef = useRef();

  return (
    <>
      <Navbar isVisible={isNavVisible} isOpen={isNavOpen} setIsNavOpen={setIsNavOpen} scrollToFooter={scrollToFooter} />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="page" timeout={{ enter: 1000, exit: 1000 }}>
          <div className="overlay">
            <img src="/assets/logo/logo.svg" alt="logo" />
          </div>
        </CSSTransition>
      </TransitionGroup>
      <Switch location={location}>
        {getRoutes().map((route: any, i: number) => {
          return (
            <Route key={i} path={`${process.env.PUBLIC_URL + route.getSlug()}`} exact>
              <route.component scrollToFooter={scrollToFooter} />
            </Route>
          );
        })}
        <Route path="*" render={(props) => <Redirect to={getDefaultPath()} />} />
      </Switch>

      <Footer contactSectionRef={contactSectionRef} />
    </>
  );
};

export default Root;
