import React from 'react';
import Slider from '../components/Slider';
import HorizontalScroll from '../components/HorizontalScroll';
import { Link, useHistory } from 'react-router-dom';

const HomePage: React.FC = () => {
  const history: any = useHistory();

  return (
    <div className="home-page">
      <section className="hero">
        <img className="background" alt="herobg" src="/assets/hero/bg1.png" />
        <div className="container">
          <Slider />
        </div>
      </section>
      <section className="about-us">
        <div className="container">
          <div className="inner-container">
            <div className="left-container">
              <div className="separator" />
              <h2>
                We are company from Poland that <strong>manufactures 9D cinemas</strong> and organizes VR events{' '}
              </h2>
              <p>
                Taught by our experience in working with cinemas from other producers, we designed a simulator tailored to the needs of the most demanding European client. The creation of <strong>our product</strong> allowed
                not only to eliminate some defects, but also to introduce a number of improvements so that the
                machines were prepared for continuous operation in tourist destinations, galleries and entertainment
                centers. We invite you to familiarize yourself with our website.
              </p>
              <button className="btn btn-blue" onClick={() => history.push('/oferta')}>
              Jump to another level of entertainment
                <img alt="person2" src="/assets/icons/arrow-right.svg" />
              </button>
            </div>
            <div className="right-container">
              <div className="top tile" onClick={() => history.push('/galeria')}>
                <h2>Reliability and build quality</h2>
                <div className="separator" />
                <h3>See our gallery</h3>
              </div>
              <div className="bottom-container">
                <div className="left tile" onClick={() => history.push('/oprogramowanie')}>
                  <h2>Software</h2>
                  <div className="separator" />
                  <h3>See our offer</h3>
                </div>
                <div className="right tile" onClick={() => history.push('/eventy')}>
                  <h2>Events</h2>
                  <div className="separator" />
                  <h3>Check them out</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-products">
        <h2 className="subtitle">Our products</h2>
        <HorizontalScroll />
      </section>
      <section className="our-team">
        <div className="container">
          <h2 className="subtitle">Our team</h2>
          <div className="inner-container">
            <div className="person">
              <img alt="person1" src="/assets/us/szadkowski.jpg" />
              <h2>
                Radosław<strong> Szadkowski</strong>
              </h2>
              <div className="separator" />
              <p>
                One of the main distributors of 9D cinemas in Poland. Many years of experience in working with them allowed him to introduce many improvements already at the design stage.
              </p>
            </div>
            <div className="person">
              <img alt="person2" src="/assets/us/mirecki.jpg" />
              <h2>
                Arkadiusz<strong> Mirecki</strong>
              </h2>
              <div className="separator" />
              <p>
              Author of the software that drives our cinemas. He has several years of experience in organizing events and developing software for virtual reality platforms.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials">
        <div className="container">
          <h2 className="subtitle">
            What our clients say <strong>about us</strong>
          </h2>
          <div className="content">
            <img className="quote bottom" alt="quote" src="/assets/icons/quote-1.svg" />
            <img className="quote top" alt="quote" src="/assets/icons/quote-2.svg" />

            <p>
            Mr. Arkadiusz diligently fulfilled the obligations imposed on him, and the application met our sublime expectations and did not disappoint from the technical side. At the same time, the presentation made a stunning impression on journalists and invited guests.
            </p>
            <div className="sign-container">
              <div className="sign">
                <h3>Tomasz Kloskowski</h3>
                <h4>
                  CEO, <b>Gdansk Airport</b>
                </h4>
              </div>
            </div>
            {/* <button className="btn btn-white arrow">
              Następne
              <img alt="next" src="/assets/icons/arrow-right-blue.svg" />
            </button> */}
          </div>
        </div>
      </section> 
    </div>
  );
};

export default HomePage;
