import React from 'react';
import { getHeaderRoutes, getPathnameSlug, getRouteLink } from '../Routes';
import { getLanguage } from '../functions/getLanguage';
import { NavLink, Link } from 'react-router-dom';
import Hamburger from './Hamburger';

interface Props {
  isVisible: boolean;
  isOpen: boolean;
  scrollToFooter: any;
  setIsNavOpen: (isOpen: boolean) => void;
}

const Navbar: React.FC<Props> = ({ isVisible, isOpen, setIsNavOpen, scrollToFooter }) => {
  return (
    <>
      <nav className={`navbar ${isVisible || isOpen ? 'active' : ''} ${isOpen ? 'opened' : ''}`}>
        <div className="container">
          <Link to={getRouteLink('Home')}>
            <div className="logo-container">
              <img alt="intensive_vr" className="logo-white" src="/assets/logo/logo_white.svg" />
              <img alt="intensive_vr" className="logo-black" src="/assets/logo/logo.svg" />
            </div>
          </Link>
          <ul className="nav-container">
            {getHeaderRoutes().map((route) => {
              return (
                <NavLink className="link" activeClassName="active" to={route.getSlug()} key={route.getName()}>
                  {route.getName()}
                </NavLink>
              );
            })}
            <button className={`btn btn-white`} onClick={() => scrollToFooter()}>
              {getLanguage() === 'pl' ? 'Contact' : 'Contact'}
              <img alt="icon_plane" src="/assets/icons/plane.svg" />
              <img alt="icon_plane" src="/assets/icons/plane-white.svg" />
            </button>
            {/* <div className="lang-switch">
              {getLanguage() === 'pl' ? (
                <NavLink to={getPathnameSlug('en')}>EN</NavLink>
              ) : (
                <NavLink to={getPathnameSlug('pl')}>PL</NavLink>
              )}
            </div> */}
          </ul>
          <ul className="nav-container-mobile">
            {getHeaderRoutes().map((route) => {
              return (
                <NavLink className="link" activeClassName="active" to={route.getSlug()} key={route.getName()}>
                  {route.getName()}
                </NavLink>
              );
            })}
            <button className={`btn btn-white`} onClick={() => scrollToFooter()}>
              {getLanguage() === 'pl' ? 'Contact' : 'Contact'}
              <img alt="icon_plane" src="/assets/icons/plane.svg" />
              <img alt="icon_plane" src="/assets/icons/plane-white.svg" />
            </button>
            {/* <div className="lang-switch">
              {getLanguage() === 'pl' ? (
                <NavLink to={getPathnameSlug('en')}>EN</NavLink>
              ) : (
                <NavLink to={getPathnameSlug('pl')}>PL</NavLink>
              )}
            </div> */}
          </ul>
          <Hamburger setIsNavOpen={setIsNavOpen} isOpen={isOpen} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
