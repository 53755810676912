import React from 'react';

interface Props {
    setIsNavOpen: (isOpen: boolean) => void,
    isOpen: boolean
}

const Hamburger: React.FC<Props> = ({ setIsNavOpen, isOpen }) => {
  return (
    <button onClick={() => setIsNavOpen(!isOpen)} className="hamburger-wrapper">
      <div className={`hamburger ${isOpen ? 'active' : ''}`}>
        <div className="line">
          <div className="mid"></div>
        </div>
      </div>
    </button>
  );
}

export default Hamburger;