import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import 'react-bnb-gallery/dist/style.css';
import { isMobile } from 'react-device-detect';
import ReactBnbGallery from 'react-bnb-gallery';
import HorizontalScroll from '../components/HorizontalScroll';

const ProductPage: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [currentProduct, setCurrentProduct] = useState<any>(null);
  const [isLightboxOpened, setIsLightboxOpened] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const params = useParams<any>();
  const location = useLocation();

  useEffect(() => {
    if (products.length > 0 && currentProduct === null) {
      console.log('loaded!');

      const array = products.filter((obj) => {
        return obj.slug === params.slug || obj.slugEN === params.slug;
      });

      if (array.length > 0) {
        setCurrentProduct(array[0]);
      } else {
        setCurrentProduct('404');
      }
    }
  }, [products, location, params]);

  useEffect(() => {
    if (isLightboxOpened) {
      document.body.style.overflow = 'hidden';
      if (!isMobile) {
        document.body.style.paddingRight = '10px';
      }
    } else {
      setTimeout(() => {
        document.body.style.overflow = 'unset';
        document.body.style.paddingRight = '0px';
      }, 300);
    }
  }, [isLightboxOpened]);

  const lightboxArray = () => {
    const tempArray: any = [];

    currentProduct.gallery?.map((item) => {
      tempArray.push(`/assets/products/${currentProduct.slug}/${item}`);
    });

    return tempArray;
  };

  useEffect(() => {
    fetch('/data/Products.json')
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, [location]);

  if (currentProduct !== '404' && currentProduct !== null && products) {
    return (
      <>
        {isLightboxOpened ? (
          <ReactBnbGallery
            show={isLightboxOpened}
            activePhotoIndex={photoIndex}
            photos={lightboxArray()}
            onClose={() => setIsLightboxOpened(false)}
            showThumbnails={false}
            opacity={0.85}
          />
        ) : null}
        <div className="software-page">
          <section className="hero-sub-page">
            <img className="bg" src="/assets/pages/software-page/hero.png" />
            <div className="container">
              <h1>{currentProduct.name}</h1>
              <h2>{currentProduct.description}</h2>
            </div>
          </section>
          {currentProduct.modules.map((item) => {
            return (
              <section className={`section-1 ${item.reverted ? 'reverse' : ''}`}>
                <div className="container">
                  <div className="cell">
                    <span className="separator" />
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                  <div className="cell">
                    <div className="image-wrapper">
                      <img className="image" src={item.image} alt="sample" />
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
        <section>
          <div className="container no-padding">
            <h2 className="subtitle">Gallery</h2>
            <div className="gallery-container">
              {currentProduct !== null &&
                currentProduct.gallery?.map((item, i) => {
                  return (
                    <div
                      className="item"
                      onClick={() => {
                        setIsLightboxOpened(true);
                        setPhotoIndex(i);
                      }}
                    >
                      <img className="maximize" src="/assets/pages/gallery-page/maximize.svg" />
                      <img key={item} alt={item} src={`/assets/products/${currentProduct.slug}/${item}`} />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
        <h2 className="subtitle">See other products</h2>
        <HorizontalScroll />
      </>
    );
  } else if (currentProduct === '404') {
    return (
      <div className="page-not-found">
        <h2>404</h2>page: <b>{window.location.href}</b> not found
        <a href="/" className="btn btn-white">
          Click here
        </a>
      </div>
    );
  } else return null;
};

export default ProductPage;
