import React from 'react';

const SoftwarePage: React.FC = () => {
  return (
    <div className="software-page">
      <section className="hero-sub-page">
        <img className="bg" src="/assets/pages/software-page/hero.png" />
        <div className="container">
          <h1>
            We can create reliable <b>software</b> for you
          </h1>
          <h2>
            Unique experience with your <b>logotype.</b>
          </h2>
        </div>
      </section>
      <section className="section-1">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2>Software</h2>
            <p>
              We have been developing software for virtual reality platforms for several years. In combination with the
              rental of equipment and service, we are able to comprehensively create a unique experience bearing the 
              logo of your product, which will present it from its best side. Or maybe you need special software that
              will allow you to train employees in an easy, cheap and efficient way? We are here for you! We encourage
              you to familiarize yourself with some of our projects!
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="https://images.unsplash.com/photo-1459550428001-4ed6ca421293?ixlib=rb-1.2.1&auto=format&fit=crop&w=2446&q=80"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-1 reverse">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2>Real estate</h2>
            <p>
            We recreated the entire building in virtual reality (with corridors, apartments, yard). Everything was interactive - the viewer could open each cabinet, move objects and destroy furniture. Our software helped real estate agents sell several apartments during the expo.
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="/assets/pages/software-page/apartment.jpg"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-1">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2>A Journey to ISS</h2>
            <p>
            Spacewalk in virtual reality written for the competition of the European Space Agency. The player was suspended from the ceiling. Thanks to the precise counting of physical elements, it was possible to reflect the conditions of the state of continuous free fall.
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="/assets/pages/software-page/ajti.png"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
	  <section className="section-1 reverse">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2>Appartment with a mezzaine</h2>
            <p>
            For the needs of a housing developer, we managed to recreate an apartment with a mezzanine. Virtual reality made it possible to reflect the real height of the apartment and show the potential buyer whether such an apartment is for him.
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="/assets/pages/software-page/antresola.jpg"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
	   <section className="section-1">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2>Bike ride</h2>
            <p>
            We created software for a virtual reality event that simulated a bicycle ride. 360 videos of the rides were recorded and played back at a speed proportional to the pedaling speed. Our software made it possible to count the travel time and organize competitions.
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="/assets/pages/software-page/bike.png"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SoftwarePage;
