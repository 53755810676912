import React, { useEffect, useState } from 'react';

const Slider: React.FC = () => {
  const [activeSlideNumber, setActiveSlideNumber] = useState<number>(1);

  const [items, setItems] = useState<any[]>([]);

  const [isFetched, setIsFetched] = useState<boolean>(false);

  const [status, setStatus] = useState('active');

  useEffect(() => {
    fetch('/data/Slider.json')
      .then((response) => response.json())
      .then((data) => setItems(data))
      .then(() => setIsFetched(true));
  }, []);

  useEffect(() => {
    setStatus('');

    const timeout1 = setTimeout(() => {
      setStatus('active');
    }, 500);

    const timeout2 = setTimeout(() => {
      setStatus('hide');
    }, 4000);

    const timeout3 = setTimeout(() => {
      if (activeSlideNumber < 4) {
        setActiveSlideNumber(activeSlideNumber + 1);
      } else {
        setActiveSlideNumber(1);
      }
    }, 5000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, [activeSlideNumber]);

  let activeItem = items[activeSlideNumber - 1];

  return (
    <div className="slider">
      <div className="squares-container">
        {items.map((item, i) => {
          return (
            <div
              onClick={() => {
                setStatus('hide');
                setTimeout(() => {
                  setActiveSlideNumber(i + 1);
                }, 300);
              }}
              className={`square ${i + 1 === activeSlideNumber ? 'active' : ''}`}
            />
          );
        })}
      </div>

      {isFetched ? (
        <div className={`item ${status}`}>
          <h1>
            {activeItem.title}
            <br /> <b>{activeItem.titleStrong}</b>
          </h1>
          <h2>
            {activeItem.subtitle}
            <b> {activeItem.subtitleStrong}</b>
          </h2>
        </div>
      ) : null}
    </div>
  );
};

export default Slider;
