import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useWindowSize } from '../Hooks/useWindowSize';
import { Link } from 'react-router-dom';

export default function HorizontalScroll() {
  const [itemsToShow, setItemsToShow] = useState(1);
  const [works, setWorks] = useState<any[]>([]);

  useEffect(() => {
    fetch('/data/Products.json')
      .then((response) => response.json())
      .then((data) => setWorks(data));
  }, []);

  const viewport: any = useWindowSize();

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    initialSlide: 2,
    slidesToShow: itemsToShow,
    slidesToScroll: 1,
    arrows: viewport.width > 1240 ? true : false,
    autoplaySpeed: 3000,
    autoplay: true,
    swipeToSlide: true,
    pauseOnHover: true
  };

  useEffect(() => {
    if (viewport.width > 1800) {
      setItemsToShow(3);
    } else if (viewport.width > 1000) {
      setItemsToShow(3);
    } else if (viewport.width > 800) {
      setItemsToShow(2);
    } else {
      setItemsToShow(1);
    }
  }, [viewport]);

  return (
    <div className="horizontal-scroll">
      <Slider {...settings}>
        {works.map((item: any, i: number) => {
          return (
            <div className="item" key="i">
              <div className="container">
                <div className="image-wrapper">
                  <img alt="img" src={item.img_url} />
                  <a href={`${item.slug !== 'kino-9d' ? `/produkt/${item.slug}` : '/oferta'}`} className="btn btn-blue">
                    SEE PRODUCT
                  </a>
                </div>
                <div className="bottom">
                  <h2>{item.name}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
