import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import HorizontalScroll from '../components/HorizontalScroll';
import scrollToComponent from 'react-scroll-to-component';

const OfferPage: React.FC<any> = ({ scrollToFooter }) => {
  const history = useHistory();

  const priceSectionRef = useRef<HTMLDivElement>(null);

  const scrollToPrice = () => {
    scrollToComponent(priceSectionRef.current, {
      offset: 0,
      align: 'center',
      duration: 500
    });
  };

  return (
    <div className="offer-page">
      <section className="hero-sub-page">
        <img
          className="bg"
          src="https://images.unsplash.com/flagged/photo-1563192114-10457fe93b23?ixlib=rb-1.2.1&auto=format&fit=crop&w=976&q=80"
        />
        <div className="container">
          <h1>
            Get familiar with <b>our offer</b>
          </h1>
          <h2>A unique product that meets the highest European standards</h2>
        </div>
      </section>
      <section className="section-1">
        <div className="container">
          <div className="cell">
            <span className="separator" />
            <h2 className="content">
              We offer <strong>9D Cinema</strong> designed and manufactured in Poland.
            </h2>
            <p>
            Our machines are assembled by hand, thanks to which we can supervise the production process at every stage. 
            It also guarantees the highest quality of our products.
              <br />
              <br />
              The cinema we offer has standard dimensions, which allows it to be easily transported with the help of freight elevators.
            </p>
          </div>
          <div className="cell">
            <div className="image-wrapper">
              <img
                className="image"
                src="https://images.unsplash.com/photo-1525540810550-5032f5d191b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
                alt="sample"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-1 reverse violet up">
        <div className="container">
          <div className="cell">
            <span className="separator white" />
            <h2>After-sales service</h2>
            <p>
            Our company helps customers not only at the stage of purchasing the machine, but also during its subsequent operation.
			      <br />
			      <br />
            Thanks to the possibility of remote access - service and after-sales service can be carried out remotely, which guarantees that the cinema will always be ready for operation.
              <br />
              <br />
              We conduct a full training in the use of the people who purchase our simulators.
            </p>
            <button className="btn btn-white" onClick={() => scrollToPrice()}>
              Check the price
            </button>
          </div>
          <div className="cell">
            <div className="image-wrapper up">
              <img className="image" src="/assets/products/kino-2.png" alt="sample" />
            </div>
          </div>
        </div>
      </section>
      <section className="product-section">
        <div className="container">
          <div className="header">
            <div className="icon">
              <img src="/assets/pages/offer-page/konstrukcja-icon.svg" />
            </div>
            <div className="heading">
              <span className="separator" />
              <h2>Design</h2>
            </div>
          </div>
          <div className="top">
            <div className="left">
              <div className="item">
                <div className="icon">
                  <img src="/assets/pages/offer-page/oswietlenie-icon.svg" />
                </div>
                <div className="content">
                  <h3>Lighting</h3>
                  <p>Stylish and intense lighting will make the machine stand out in any environment.</p>
                </div>
              </div>
              <div className="item violet">
                <div className="icon">ikonka</div>
                <div className="content">
                  <h3>Chairs</h3>
                  <p>
                  Special profiling of the seats will make the screening more comfortable than in many cinemas, and their futuristic appearance will attract crowds of viewers.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  <img src="/assets/pages/offer-page/joystick-icon.svg" />
                </div>
                <div className="content">
                  <h3>Joysticks</h3>
                  <p>
                  The reinforced construction of the joysticks means that even the most terrified customers will not tear them away.
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <img src="/assets/products/kino-1.png" />
            </div>
          </div>
          <div className="bottom">
            <div className="item violet">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Special effects</h3>
                <p>
                The reinforced construction of the joysticks means that even the most terrified customers will not tear them away.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src="/assets/pages/offer-page/personalizacja-icon.svg" />
              </div>
              <div className="content">
                <h3>Personalization</h3>
                <p>On special request, we are able to adapt the machine to the customer's needs.</p>
              </div>
            </div>
            <div className="item violet">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Cooling</h3>
                <p>Our proprietary heat dissipation system will make our cinema the hottest emotions.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-section violet software">
        <div className="container">
          <div className="header">
            <div className="icon">
              <img src="/assets/pages/offer-page/software-icon.svg" />
            </div>
            <div className="heading">
              <span className="separator white" />
              <h2>Software</h2>
            </div>
          </div>
          <div className="top">
            <div className="left">
              <div className="item">
                <div className="icon">ikonka</div>
                <div className="content">
                  <h3>Intuitive</h3>
                  <p>
                  Our software displays clear messages, so even casual employees will quickly get used to the machine.
                  </p>
                </div>
              </div>
              <div className="item violet">
                <div className="icon">ikonka</div>
                <div className="content">
                  <h3>Rich repertoire</h3>
                  <p>
                  A huge database of games and applications will ensure that even the most faithful customers will not be bored with your offer.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon">ikonka</div>
                <div className="content">
                  <h3>Remote access</h3>
                  <p>
                  Każda maszyna ma skonfigurowany zdalny dostęp, byśmy mogli szybko i sprawnie serwisować, i aktualizować nasze symulatory.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="item violet">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Linux-based</h3>
                <p>
                Our machine is based on the Linux operating system, not the unsupported Windows 7, which guarantees reliability.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Financial reports</h3>
                <p>A transparent system of financial reports sent by e-mail allows you to effectively settle employees. </p>
              </div>
            </div>
            <div className="item violet">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Updates</h3>
                <p>We are constantly developing our software thanks to the suggestions of customers and our employees.</p>
              </div>
            </div>
          </div>
          <button onClick={() => history.push('/oprogramowanie')} className="btn btn-white">
            Request software
          </button>
        </div>
      </section>
      <section className="product-section reverse">
        <div className="container">
          <div className="header">
            <div className="icon">
              <img src="/assets/pages/offer-page/hardware-icon.svg" />
            </div>
            <div className="heading">
              <span className="separator" />
              <h2>Hardware</h2>
            </div>
          </div>
          <div className="top">
            <div className="left">
              <div className="item">
                <div className="icon">
                  {' '}
                  <img src="/assets/pages/offer-page/powerful-icon.svg" />
                </div>
                <div className="content">
                  <h3>Powerful computer</h3>
                  <p>The heart of the machine is a powerful computer with a 10th generation Intel processor.</p>
                </div>
              </div>
              <div className="item violet">
                <div className="icon">ikonka</div>
                <div className="content">
                  <h3>GPU</h3>
                  <p>
                  Plenty of computing power provided by two GTX 1650 SUPER graphics cards will keep temperatures under control.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  {' '}
                  <img src="/assets/pages/offer-page/minimalism-icon.svg" />
                </div>
                <div className="content">
                  <h3>Minimalism</h3>
                  <p>
                  Because we use one computer instead of three, we forgo many network connections that could fail.
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <img src="/assets/pages/offer-page/hardware-photo.png" />
            </div>
          </div>
          <div className="bottom">
            <div className="item violet">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Spare parts</h3>
                <p>
                As we mainly use European suppliers, ordering spare parts or upgrades will take place in no time.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                {' '}
                <img src="/assets/pages/offer-page/chlodzenie-icon.svg" />
              </div>
              <div className="content">
                <h3>Performance</h3>
                <p>
                We use super-fast NVMe drives that make videos load even faster and allow to serve more people.
                </p>
              </div>
            </div>
            <div className="item violet">
              <div className="icon">ikonka</div>
              <div className="content">
                <h3>Touchscreen</h3>
                <p>
                A large, 24/32" touch panel with a capacitive screen guarantees the experience known from modern smartphones.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="price-section">
        <div className="container">
          <h2 className="subtitle">Price</h2>
          <div className="available-options" ref={priceSectionRef}>
            <div className="option standard" onClick={() => scrollToFooter()}>
              <h3>STANDARD</h3>
              <p>from 14 000 EUR</p>
              <ul>
                <li>9D cinema</li>
                <li>24" touchscreen</li>
                <li>12 months warranty</li>
                <li>12 months of software update</li>
              </ul>
            </div>
            <div className="option premium" onClick={() => scrollToFooter()}>
              <h3>PREMIUM</h3>
              <p>Request price</p>
              <ul>
                <li>9D Cinema</li>
                <li>32" touchscreen</li>
                <li>24 months warranty</li>
                <li>24 months of software update</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <HorizontalScroll />
    </div>
  );
};

export default OfferPage;
